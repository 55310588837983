import { SVGProps, Ref, forwardRef } from 'react'

const SvgHandshake = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m15.16 9.286-4.415 1.469a1.453 1.453 0 0 1-1.07-.066 1.48 1.48 0 0 1-.74-.784 1.504 1.504 0 0 1-.018-1.084c.129-.351.384-.64.714-.809l4.277-2.164a2.976 2.976 0 0 1 2.365-.133l6.314 2.318v8.772l-7.435 1.43"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.414 16.752h3.054l4.385 5.112c.223.27.52.461.851.55.331.089.68.07 1.001-.054.32-.124.597-.346.792-.639.196-.291.3-.639.3-.994v-.8l.219.092a1.996 1.996 0 0 0 1.926-.216c.28-.197.51-.463.668-.773.158-.31.24-.656.24-1.008h.611c.34 0 .674-.098.964-.284a1.88 1.88 0 0 0 .675-.769 1.972 1.972 0 0 0-.173-1.995l-3.868-4.68M9.883 7.734l-.44-.476a2.276 2.276 0 0 0-1.2-.64 2.006 2.006 0 0 0-1.285.213L1.414 9.765M1.414 5.883v13.058M22.586 18.94V5.884M12 20.352l-1.588-2.117M13.059 16.117l2.117 2.118"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgHandshake)
export default ForwardRef
